<template>
    <a-spin :spinning="loading" :tip="loadingTip">
    <div>
        <a-list :grid="{ gutter: 16, column: 4 }" :data-source="data">
                <a-list-item slot="renderItem" slot-scope="item, index">
                 <div style="text-align:center">
                    <file-pdf-outlined v-if="item.title.endsWith('.pdf')"/>
                        <a-icon type="file-word" theme="twoTone" style="font-size:60px;"  v-else-if="item.title.endsWith('.doc') || item.title.endsWith('.docx') "/>
                        <a-icon type="file-zip" theme="twoTone" v-else-if="item.title.endsWith('.zip')||item.title.endsWith('.rar')"/>
                        <a-icon type="file-ppt" theme="twoTone" v-else-if="item.title.endsWith('.ppt')||item.title.endsWith('.pptx')"/>
                        <a-icon type="file-excle" theme="twoTone" v-else-if="item.title.endsWith('.xls')||item.title.endsWith('.xlsx')"/>
                        <a-icon type="file" theme="twoTone" style="font-size:60px;" v-else/>
                 </div>
                 <div  style="text-align:center;padding:10px">
                    <a-button type="link" @click="downloadfile(item)">{{ item.title }}</a-button>
                    
                 </div>
                </a-list-item>
        </a-list>
    </div>
</a-spin>
</template>
<script>
import request from "@/request"
var _this;
export default{
    name:"attachment",
    props:{
        data:Array
    },
    data(){
        return{
            loading:false,
            loadingTip:"加载中..."
        }
    },
    mounted(){
        _this=this;
    },
    methods:{
        downloadfile(info){
            this.loading=true;
            request.downloadFile(info.url,info.title, d=>{
                _this.loadingTip="已完成"+d+"%";
            if(d==100){
              setTimeout(function(){
                _this.loading = false;
              },2000);
               
            }
          },()=>{
            _this.loading = false;
          })
        }
    }
}
</script>